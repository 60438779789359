import React, { FC } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'gatsby';

import Image from 'common/Image';
import { LanguageSelector, LanguageSelectorPopup } from 'components/LangugageSelector';

import { HeaderProps, Language } from './models';

import './Header.scss';

function getCurrentLanguage(list: Language[] | undefined): Language | undefined {
  if (typeof window !== 'undefined' && list) {
    const current = list.find(({ code }) => code === window.location.pathname.split('/')[1]);

    return current;
  }

  return undefined;
}

const Header: FC<HeaderProps> = ({
  data: { logo, navs, socialMedia },
  settings: { avaliableLanguages, popupLanguage },
  alterlogo,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const currentLanguage = getCurrentLanguage(avaliableLanguages);

  return (
    <header className="fixed-top">
      <div className="header mx-auto">
        {navs && navs.length > 0 ? (
          <Navbar expand="md" variant="dark" className="py-0" expanded={expanded}>
            <>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={() => setExpanded(!expanded)}
              >
                <span className="fas fa-bars" />
              </Navbar.Toggle>
              <Navbar.Collapse id="menu">
                <Nav className="header__nav">
                  {navs.map((navItem) => (
                    <Link
                      to={navItem.url}
                      key={navItem.name}
                      className="text-uppercase nav-link"
                      title={navItem.name}
                      data-testid="header-nav-link"
                      onClick={() => setExpanded(!expanded)}
                    >
                      {navItem.name}
                    </Link>
                  ))}
                </Nav>
              </Navbar.Collapse>
            </>
          </Navbar>
        ) : (
          <></>
        )}

        <div className="header__logo">
          <Navbar.Brand href={currentLanguage?.url || '/'}>
            <Image className="logo" imageData={alterlogo || logo} alt={logo?.altText || ''} />
          </Navbar.Brand>
        </div>

        {socialMedia && socialMedia.length > 0 ? (
          <Nav className="d-none d-md-flex align-items-center header__social-media">
            {socialMedia.map((mediaItem) => (
              <a
                href={mediaItem.url}
                key={mediaItem.url}
                target="_blank"
                className="nav-icon mx-1"
                data-testid="header-nav-social"
                title={mediaItem.name}
                rel="noreferrer"
              >
                <i className={`fab fa-${mediaItem.name}`} />
              </a>
            ))}
          </Nav>
        ) : (
          <></>
        )}

        {avaliableLanguages && avaliableLanguages.length > 1 ? (
          <div className={navs && navs.length > 0 ? '' : 'header__language'}>
            <LanguageSelector list={avaliableLanguages} />
            <LanguageSelectorPopup list={avaliableLanguages} title={popupLanguage} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </header>
  );
};

export default Header;
